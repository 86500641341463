import { Outlet } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <>
        <Outlet/>
    </>
  );
}

export default App;
