import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from './component/home/Home';
import ReportPage from './component/report/ReportPage';
import LoginPage from './component/login/LoginPage';
import ErrorPage from './component/error/ErrorPage';
import { AuthProvider } from './authContext';
import RequireAuth from './RequireAuth';


const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage/>,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "/reportpage/:campaign_name",
        element: <ReportPage />,
      },
      {
        path: "/dashboard",
        element: <RequireAuth><Home /></RequireAuth>,
      },
    ]
  }
]
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthProvider>
    <RouterProvider router={appRouter} />
  </AuthProvider>
);
