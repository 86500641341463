import React from "react";
import { useAuth } from "./authContext";
import { Navigate, useNavigate } from "react-router-dom";

const RequireAuth = ({ children }) => {

    // let loggedIn;
    // loggedIn = localStorage.getItem('loggedIn')
    const auth = useAuth()

    console.log('1111111111111111111',auth)


    if (!auth.loggedin || auth.loggedin == false) {
        return <Navigate to="/" />;
    } else return children;
};

export default RequireAuth;
