import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { Navigate } from "react-router-dom";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [loggedin, setLoggedin] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    // const  navigate = useNavigate()


    const login = async (username,password) => {
        console.log('auth login called')
        if (username === 'noamaan@forksurge.com' && password === 'Fork1234') {
            setLoggedin(true);
            localStorage.setItem("loggedIn", true);
            return true
            //   setErrorMsg("");
        } else {
            setErrorMsg('error');
            return false
        }


    };

    const logout = () => {
        console.log('logout called')
        setLoggedin(false)
        localStorage.removeItem('loggedIn')
        
    };

    return (
        <AuthContext.Provider value={{ loggedin, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
