import React from 'react'
import errorImage from './errorpage.png'
import { Link } from 'react-router-dom'
const ErrorPage = () => {
    return (
        <>
            <div className='w-full relative h-screen bg-black'>
                <Link to="/">
                    <img className='w-[100%] h-[100%] object-contain' src={errorImage} alt='Error image' />
                </Link>
            </div>
        </>
    )
}

export default ErrorPage
