import React, { useState, useEffect } from 'react'
import { BsCalendarDate } from 'react-icons/bs'
import { CgWebsite } from 'react-icons/cg'
import { BiLogOutCircle, BiSolidReport } from 'react-icons/bi'
import ReportTable from './ReportTable'
import SiteReport from './SiteReport'
import { Link, json, useParams } from 'react-router-dom'
import { MdCampaign } from 'react-icons/md'
import { useAuth } from '../../authContext'
import { useNavigate } from 'react-router-dom'

const ReportPage = (props) => {
    const [loading, setLoading] = useState(true);
    const [showTable, setShowTable] = useState(false);
    const [siteWiseData, setSiteWiseData] = useState([])
    const [dateWiseData, setDateWiseData] = useState([])
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('')
    const [filteredData, setFilteredData] = useState([]);
    const navigate = useNavigate();

    const { campaign_name } = useParams()
    const auth = useAuth()

    const handleLogout=()=>{
        auth.logout()
        navigate('/')
    }

    useEffect(() => {
        async function fetchData() {
            const apiUrl = 'https://traking.click2mediaoffer.com/sitewise.php';

            try {
                const response = await fetch(apiUrl);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const jsonData = await response.json();

                setSiteWiseData(jsonData)
                setLoading(false)
                // console.log("chjcj", jsonData)
            } catch (error) {
                setLoading(false)
                console.error('Error fetching data:', error);
                // setLoading(false);
            }
        }

        fetchData();
    }, []);


    useEffect(() => {
        async function fetchData() {
            const apiUrl = 'https://traking.click2mediaoffer.com/datawise.php';

            try {
                const response = await fetch(apiUrl);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const jsonData = await response.json();

                setDateWiseData(jsonData)
                // setFilteredData(jsonData)
                console.log("checking datewisedata", jsonData)
                setLoading(false)
                // console.log("datewise", jsonData)
            } catch (error) {
                setLoading(false)
                console.error('Error fetching data:', error);
                // setLoading(false);
            }
        }

        fetchData();
    }, []);


    const siteWiseFiltered = siteWiseData.filter(item => {
        return item.campaign_name == campaign_name
    })

    const dateWiseFiltered = dateWiseData.filter(item => {
        // console.log('dddddddddddd', item.campaign_name)
        return item.campaign_name == campaign_name
    })

    // console.log("datewisefilter", dateWiseFiltered)



    const filterDataByDateRange = () => {
        if (!fromDate) {
            setFilteredData(dateWiseFiltered);
            console.log("datewise filtered ddd", dateWiseFiltered)
            return;
        }

        // console.log("sssssssss", filteredData)
        const fromDateObj = fromDate ? new Date(fromDate + 'T00:00:00') : null;
        // const toDateObj = toDate ? new Date(toDate + 'T00:00:00') : null;

        // console.log("fromDateObj", fromDateObj)

        const filtered = dateWiseFiltered.filter((item) => {
            const startDateObj = new Date(item.date);
            // const endDateObj = new Date(item.date);
            // console.log('------------------------------------------')
            // console.log('item sno', item.sno)
            console.log("fromDateObj", fromDateObj)
            console.log("startDateObj ", startDateObj)
            // console.log(" from ", fromDateObj)
            // console.log("end", endDateObj)
            // console.log("to", toDateObj)

            return fromDateObj <= startDateObj;
        });

        setFilteredData(filtered);
        // console.log("checking filtered", filtered)
    };

    useEffect(() => {
        filterDataByDateRange();
    }, [fromDate, toDate, dateWiseData]);


    return (
        <>
            <div className='w-full min-h-screen bg-[#050505]/90'>

                <div className='w-full flex flex-col items-center'>
                    <div className='w-[90%] py-5 flex justify-between'>
                        <div className='flex items-center text-white'>
                            <h1 className='text-4xl text-white font-bold'>{campaign_name}</h1>
                        </div>
                        <div className=''>
                            <button onClick={handleLogout} className='flex items-center justify-center text-sm bg-[#2C2C2A] hover:bg-[#368] text-white shadow-md px-4 py-1.5 rounded-md cursor-pointer font-semibold'>
                                Logout <BiLogOutCircle className='ml-1 text-lg' />
                            </button>
                        </div>
                    </div>

                    <div className='w-[90%] flex items-center justify-between mt-10'>
                        <div className='flex gap-2'>

                            <button onClick={() => setShowTable(false)} className={`w-32 flex hover:bg-[#368] items-center justify-center ${showTable ? "bg-[#2C2C2A]" : "bg-[#368]"}  shadow-md text-white font-semibold py-1.5 rounded-md`}>
                                Date Wise <BsCalendarDate className='text-base ml-2' />
                            </button>
                            <button onClick={() => setShowTable(true)} className={`w-32 flex hover:bg-[#368] items-center justify-center ${showTable ? "bg-[#368]" : "bg-[#2C2C2A]"}  shadow-md text-white font-semibold py-1.5 rounded-md`}>
                                Site Wise <CgWebsite className='text-lg ml-2' />
                            </button>
                            <Link to="/dashboard">
                                <button className='w-32 flex items-center justify-center bg-[#2C2C2A] hover:bg-[#368] shadow-md text-white font-semibold py-1.5 rounded-md'>
                                    Campaign <MdCampaign className='text-xl ml-2' />
                                </button>
                            </Link>

                        </div>

                        <div className='flex gap-2'>
                            {
                                showTable ? "" : <>
                                    <div className="flex bg-[#2C2C2A] shadow-md py-2 pl-3 pr-1.5 rounded-md">
                                        <label htmlFor="fromDate" className="text-white">Date</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            value={fromDate}
                                            onChange={(e) => setFromDate(e.target.value)}
                                            className="ml-2 pl-2 pr-1 border-gray-300 rounded focus:ring focus:ring-blue-500 focus:border-blue-500"
                                        />
                                    </div>


                                    {
                                        (!fromDate == "") && <>
                                            <button
                                                onClick={() => {
                                                    setFromDate('');
                                                    setToDate('');
                                                }}
                                                className="bg-[#2C2C2A] text-white px-4 py-1.5 rounded-md cursor-pointer font-semibold"
                                            >
                                                Clear Date
                                            </button>
                                        </>
                                    }
                                </>
                            }

                        </div>
                    </div>

                </div>

                <div className="w-full mt-5 flex justify-center">
                    {showTable ? <SiteReport data={siteWiseFiltered} loading={loading} /> : <ReportTable data={filteredData} loading={loading} />}
                </div>

            </div>
        </>
    )
}

export default ReportPage
