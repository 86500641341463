import React, { useState, useEffect } from 'react'
import { MdCampaign } from 'react-icons/md'
import { BiLogOutCircle, BiSolidFileExport, BiSolidReport } from 'react-icons/bi'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useAuth } from '../../authContext'
// import data1 from './sample.json'

const Home = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [filteredData, setFilteredData] = useState([]); // Initialize with an empty array
    const navigate = useNavigate()

    useEffect(() => {
        async function fetchData() {
            const apiUrl = 'https://traking.click2mediaoffer.com/rm.php';

            try {
                const response = await fetch(apiUrl);

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const jsonData = await response.json();


                setData(jsonData);
                setFilteredData(jsonData); // Initially, set filteredData to all data
                console.log("campaign data", jsonData)
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        filterDataByDateRange();
    }, [fromDate, toDate]);

    const filterDataByDateRange = () => {
        if (!fromDate || !toDate) {
            // If fromDate or toDate is empty, show all data
            setFilteredData(data);
            return;
        }

        const fromDateObj = fromDate ? new Date(fromDate + 'T00:00:00') : null;
        const toDateObj = toDate ? new Date(toDate + 'T00:00:00') : null;

        console.log("start date from select", fromDateObj, toDateObj)

        // Filter data based on the date range
        const filtered = data.filter((item) => {
            const startDateObj = new Date(item.start_date);
            const endDateObj = new Date(item.end_date);
            // console.log('item start date',item.start_date,typeof item.start_date)
            console.log('------------------------------------------')
            console.log('item sno', item.sno)
            console.log("start ", startDateObj)
            console.log(" from ", fromDateObj)
            console.log("end", endDateObj)
            console.log("to", toDateObj)

            return startDateObj >= fromDateObj && startDateObj <= toDateObj;
        });

        setFilteredData(filtered);
        console.log("checking filtered", filtered)
    };

    const auth = useAuth()


    const handleLogout = () => {
    auth.logout()
    navigate('/')
   
    }


    return (
        <>
            <div className='w-full min-h-screen bg-[#050505]/90'>

                <div className='w-full flex flex-col items-center'>
                    <div className='w-[90%] py-5 flex justify-between'>
                        <div className='flex items-center text-white'>
                            <BiSolidReport className='text-4xl mr-1' />
                            <h1 className='text-4xl text-white font-bold'>Report Access</h1>
                        </div>
                        <div className=''>
                            <button onClick={handleLogout} className='flex items-center justify-center text-sm bg-[#2C2C2A] hover:bg-[#368] text-white shadow-md px-4 py-1.5 rounded-md cursor-pointer font-semibold'>
                                Logout <BiLogOutCircle className='ml-1 text-lg' />
                            </button>
                        </div>
                    </div>

                    <div className='w-[90%] flex items-center justify-between mt-10'>
                        <div className='flex gap-2'>
                            <button className='w-32 flex items-center justify-center bg-[#368] shadow-md text-white font-semibold py-1.5 rounded-md'>
                                Campaign <MdCampaign className='text-xl ml-2' />
                            </button>
                            {/* <button className='w-32 flex items-center justify-center bg-[#368] shadow-md text-white font-semibold py-1.5 rounded-md'>
                                    Export <BiSolidFileExport className='text-lg ml-2' />
                                </button> */}
                        </div>

                        <div className='flex gap-2'>
                            <div className="flex bg-[#2C2C2A] shadow-md py-2 pl-3 pr-1.5 rounded-md">
                                <label htmlFor="fromDate" className="text-white">From</label>
                                <input
                                    type="date"
                                    id="fromDate"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    className="ml-2 pl-2 pr-1 border-gray-300 rounded focus:ring focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>
                            <div className="flex bg-[#2C2C2A] shadow-md py-2 pl-3 pr-1.5 rounded-md">
                                <label htmlFor="toDate" className="text-white">To</label>
                                <input
                                    type="date"
                                    id="toDate"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    className="ml-2 pl-2 pr-1 border-gray-300 rounded focus:ring focus:ring-blue-500 focus:border-blue-500"
                                />
                            </div>

                            {
                                (!fromDate == "" || !toDate == "") && <>
                                    <button
                                        onClick={() => {
                                            setFromDate('');
                                            setToDate('');
                                        }}
                                        className="bg-[#2C2C2A] text-white px-4 py-1.5 rounded-md cursor-pointer font-semibold"
                                    >
                                        Clear Date
                                    </button>
                                </>
                            }

                        </div>
                    </div>

                </div>

                <div className="w-full mt-5 flex justify-center">
                    <table className="table-auto w-[90%] shadow-2xl mb-10">
                        <thead className="sticky top-0 border border-[#212122] bg-[#595652] shadow-xl text-white font-medium">
                            <tr>
                                <th className="font-medium"></th>
                                <th className="px-4 py-2 font-medium">Campaign Name</th>
                                <th className="px-4 py-2 font-medium">Start Date</th>
                                <th className="px-4 py-2 font-medium">End Date</th>
                                <th className="px-4 py-2 font-medium">Status</th>
                                <th className="px-4 py-2 font-medium">Impression</th>
                                <th className="px-4 py-2 font-medium">Reach</th>
                                <th className="px-4 py-2 font-medium">Clicks</th>
                                <th className="px-4 py-2 font-medium">Geo</th>
                                <th className="px-4 py-2 font-medium"></th>
                            </tr>
                        </thead>
                        {
                            loading ? <>
                                <tbody className='text-white border border-[#212122]'>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                    <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                        <td className="px-4 py-5 text-center">
                                            <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </>
                                : <>
                                    <tbody className='text-white border border-[#212122]'>

                                        {(1) ? ( // Check if fromDate or toDate is set, if yes, display filteredData
                                            filteredData.map((item, index) => (
                                                <tr key={index} className={index % 2 === 0 ? 'even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C]' : 'hover:bg-[#262B2F] border border-[#212122]'}>
                                                    <td className="px-4 py-5 text-center">
                                                        <span className='px-3 py-1 rounded-lg   text-white text-sm bg-[#368] shadow-xl'>{item.s_no}</span>
                                                    </td>
                                                    <td className="px-4 py-5 text-center">{item.campaign}</td>
                                                    <td className="px-4 py-5 text-center">{item.start_date}</td>
                                                    <td className="px-4 py-5 text-center">{item.end_date}</td>
                                                    <td className="px-4 py-5 text-center">
                                                        <span className='px-3 py-1 rounded-lg text-white text-sm bg-[#888] shadow-xl'>
                                                            {item.status}
                                                        </span>
                                                    </td>
                                                    <td className="px-4 py-5 text-center">
                                                        {item.impressions.toLocaleString()}
                                                    </td>
                                                    <td className="px-4 py-5 text-center">
                                                        {item.reach.toLocaleString()}
                                                    </td>
                                                    <td className="px-4 py-5 text-center">
                                                        {item.clicks.toLocaleString()}
                                                    </td>
                                                    <td className="px-4 py-5 text-center">{item.geo}</td>
                                                    <td className="px-4 py-5 text-center">
                                                        <Link to={`/reportpage/${item.campaign}`}>
                                                            <button className='px-2 py-2 font-medium hover:scale-110 duration-100 bg-[#368] rounded-md text-sm shadow-xl'>View Report</button>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : <></>}
                                    </tbody>
                                </>
                        }
                    </table>
                </div>

            </div>
        </>
    )


}

export default Home
