import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ReportTable = ({ data, loading }) => {

    // console.log("datewise data table", data)
    return (
        <>
            <table className="table-auto w-[90%] shadow-2xl mb-10">
                <thead className="sticky top-0 border border-[#212122] bg-[#595652] shadow-xl text-white font-medium">
                    <tr>
                        <th className="font-medium"></th>
                        {/* <th className="px-4 py-2 font-medium">Name</th> */}
                        <th className="px-4 py-2 font-medium">Date</th>
                        <th className="px-4 py-2 font-medium">Impression</th>
                        <th className="px-4 py-2 font-medium">Clicks</th>
                        <th className="px-4 py-2 font-medium">CTR</th>
                    </tr>
                </thead>

                {
                    loading ? <>
                        <tbody className='text-white border border-[#212122]'>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                            <tr className="even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] 'hover:bg-[#262B2F] border border-[#212122]">
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                                <td className="px-4 py-5 text-center">
                                    <div className='w-full bg-gray-100 h-5 animate-pulse'></div>
                                </td>
                            </tr>
                        </tbody>
                    </>
                        : <>
                            <tbody className='text-white border border-[#212122]'>
                                {
                                    data && data.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index} className='even:bg-[#2C2C2A] hover:bg-[#262B2F] odd:bg-[#1C1C1C] border border-[#212122]'>
                                                    <td className=" px-4 py-5 text-center">
                                                        <span className='px-3 py-1 rounded-lg text-white text-sm bg-[#368] shadow-xl'>{index + 1}</span>
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">{item.date}</td>
                                                    <td className=" px-4 py-5 text-center">
                                                        {item.impression.toLocaleString()}
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">
                                                        {item.clicks.toLocaleString()}
                                                    </td>
                                                    <td className=" px-4 py-5 text-center">{item.ctr}</td>
                                                </tr >
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </>
                }
            </table >
        </>
    )
}

export default ReportTable
